import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { Doctor } from './doctor';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GetDoctorResponse } from './getdoctorresponse';


@Component({
    selector: 'app-doctors',
    templateUrl: './doctors.component.html',
    styleUrls: ['./doctors.component.scss']
})
export class DoctorsComponent implements OnInit {

    getDoctorsUrl = '/regenero/get-doctors';

    doctors: Doctor[] = [];

    regeneroBaseUrl = '';

    filteredDoctors: Doctor[] = [];

    private _listFilter = '';

    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredDoctors = this.performFilter(value);
    }

    constructor(
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService
    ) {

        this.regeneroBaseUrl = _regeneroBaseUrl;
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        else {
            this.getDoctors();
        }
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    performFilter(filterBy: string): Doctor[] {
        filterBy = filterBy.toLocaleLowerCase();
        return this.doctors!.filter(
            (doctor: Doctor) =>
                doctor.name?.toLocaleLowerCase().includes(filterBy)

        );
    }
    getDoctors() {
        this._apiRequestService
            .sendRequest<{}, GetDoctorResponse>(this.regeneroBaseUrl, true, {}, true, this.getDoctorsUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: GetDoctorResponse) => {
                this.doctors = response.doctors as Doctor[];
                this.filteredDoctors = this.doctors!;
            });
    }
}
