import { Component } from '@angular/core';

@Component({
  selector: 'app-deletedoctorpopup',
  templateUrl: './deletedoctorpopup.component.html',
  styleUrls: ['./deletedoctorpopup.component.scss']
})
export class DeletedoctorpopupComponent {

}
