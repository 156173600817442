import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { SigninComponent, BannersComponent, OrderBannersComponent, UpdatebannerComponent, AddbannerComponent  } from '@prekog/pw-common';
import { HomeComponent } from './home/home.component';
import { SpecialityPriceListComponent } from './specialitypricelist/specialitypricelist.component';
import { PricelistComponent } from './pricelist/pricelist.component';
import { AddexaminationComponent } from './pricelist/addexamination/addexamination.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { UpdatedoctorComponent } from './doctors/updatedoctor/updatedoctor.component';
import { AddconsultationhourComponent } from './doctors/addconsultationhour/addconsultationhour.component';
import { AdddoctorComponent } from './doctors/adddoctor/adddoctor.component';
import { AddjobtitleComponent } from './doctors/addjobtitle/addjobtitle.component';
import { SpecialitiesComponent } from './specialities/specialities.component';
import { AddspecialityComponent } from './specialities/addspeciality/addspeciality.component';
import { UpdatespecialityComponent } from './specialities/updatespeciality/updatespeciality.component';
import { BasedatasComponent } from './basedatas/basedatas.component';
import { ColleaguesComponent } from './colleagues/colleagues.component';




const routes: Routes = [
    { path: '', component: SigninComponent },
    { path: 'home', component: HomeComponent },
    { path: 'orderbanners', component: OrderBannersComponent },
    { path: 'banners', component: BannersComponent },
    { path: 'banners/:id', component: UpdatebannerComponent },
    { path: 'addbanner', component: AddbannerComponent },
    { path: 'specialitypricelist', component: SpecialityPriceListComponent },
    { path: 'pricelist/:id', component: PricelistComponent },
    { path: 'addexamination/:id', component: AddexaminationComponent },
    { path: 'doctors', component: DoctorsComponent },
    { path: 'doctor/:id', component: UpdatedoctorComponent },
    { path: 'addconsultationhour/:id', component: AddconsultationhourComponent },
    { path: 'adddoctor', component: AdddoctorComponent },
    { path: 'addjobtitle/:id', component: AddjobtitleComponent },
    { path: 'specialities', component: SpecialitiesComponent },
    { path: 'addspeciality', component: AddspecialityComponent },
    { path: 'updatespeciality/:id', component: UpdatespecialityComponent },
    { path: 'basedatas', component: BasedatasComponent },
    { path: 'colleagues', component: ColleaguesComponent }



];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
